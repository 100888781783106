import React from "react";
import { Container, Row, Col, Card, CardBody, Badge, Button } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";

const QrCodePix = () => {
  document.title = "API QR Code Pix | Nautt Finance";

  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname.includes("sandbox")) {
      return "https://api-sandbox.nautt.finance/v1";
    } else {
      return "https://api.nautt.finance/v1";
    }
  };

  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Copiado al portapapeles");
    } catch (error) {
      toast.error("Error al copiar al portapapeles");
    }
  };

  const baseUrl = getBaseUrl();

  const curlCodeSearch = `curl -i -X POST ${baseUrl}/ordens-pix/busca \\
    -H "Authorization: Bearer <YOUR_TOKEN>" \\
    -H "Content-Type: application/json" \\
    -d '{
        "itens_pagina": 10,
        "pagina": 1,
        "status": "pago",
        "order": "desc",
        "pdv_id": 28
    }'`;

  const searchSuccessResponse = `{
    "mensagem": "Busca de Ordens Pix realizada com sucesso.",
    "dados": {
        "current_page": 1,
        "total": 344,
        "data": [
            {
                "id": 347,
                "user_id": 209,
                "pdv_id": 28,
                "atendente_id": 35,
                "documento": null,
                "nome": null,
                "qrcode": "IVfnjbvvbeo758Glndlandhigvojjohfyyebvbvo",
                "descricao": "Ordem de Pix de <b>R$ 1933.92</b>",
                "cotacao_nautt": "6.3242",
                "total_brl": "1933.9200",
                "total_token": "305.7968",
                "status": "expirada",
                "created_at": "2024-12-17T14:56:23.000000Z",
                "updated_at": "2024-12-17T14:56:23.000000Z"
            }
        ]
    }
}`;

  const curlCodeCotar = `curl -i -X POST ${baseUrl}/cotar \\
    -H "Content-Type: application/json" \\
    -d '{
        "moeda": "ARS",
        "total": "200000.00",
        "destino": "USDT",
        "pdv_id": "1"
    }'`;

  const curlCodeCotarSinpdv = `curl -i -X POST ${baseUrl}/cotar \\
    -H "Authorization: Bearer <YOUR_TOKEN>" \\
    -H "Content-Type: application/json" \\
    -d '{
        "moeda": "ARS",
        "total": "200000.00",
        "destino": "USDT"
    }'`;

  const cotarSuccessResponse = `{
    "mensagem": "Cotización calculada con éxito",
    "dados": {
        "total_brl": 200.00,
        "cotacao": 6.15
    }
}`;

  const curlCodeGenerate = `curl -i -X POST ${baseUrl}/gerar-qr \\
    -H "Content-Type: application/json" \\
    -d '{
        "total_brl": 100,
        "expiracao": 600,
        "pdv_id": 1,
        "atendente_id": 1
    }'`;

  const curlCodeGenerateSinpdv = `curl -i -X POST ${baseUrl}/gerar-qr \\
    -H "Authorization: Bearer <YOUR_TOKEN>" \\
    -H "Content-Type: application/json" \\
    -d '{
        "total_brl": 100,
        "expiracao": 600
    }'`;

  const curlCodeView = `curl -i -X GET "${baseUrl}/ordem?ordem_id=1" \\
    -H "Authorization: Bearer <YOUR_TOKEN>" \\
    -H "Content-Type: application/json"`;

  const generateSuccessResponse = `{
    "mensagem": "QR Code Pix generado con éxito",
    "dados": {
        "id": 1,
        "status": "novo",
        "qrcode": "IVfnjbvvbeo758Glndlandhigvojjohfyyebvbvo"
    }
}`;

  const viewSuccessResponse = `{
    "mensagem": "Orden Pix encontrada con éxito",
    "dados": {
        "id": 1,
        "status": "novo",
        "qrcode": "IVfnjbvvbeo758Glndlandhigvojjohfyyebvbvo",
        "created_at": "2024-01-01T00:00:00.000000Z"
    }
}`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="QR Code Pix" pageTitle="API" />

          {/* Generar QR Code Pix */}
          <Row className="mb-4">
            <Col xl={8}>
              <Card>
                <CardBody>
                  <div className="live-preview">
                    <div className="d-flex align-items-center mb-4">
                      <div className="flex-grow-1">
                        <h2 className="mb-0">Generar QR Code Pix (Brazil)</h2>
                      </div>
                      <div className="flex-shrink-0">
                        <Badge color="primary" className="fs-5">
                          POST
                        </Badge>
                        <br />
                        <small className="text-muted">/gerar-qr</small>
                      </div>
                    </div>

                    <div className="mb-4">
                      <h5>Descripción</h5>
                      <p>
                        Genera un QR Code Pix a partir de los dados
                        proporcionados. El PDV ID y el Atendente ID son
                        opcionais, pero se no les proporcionados, es necesario
                        proporcionar el Bearer Token para poder identificar al
                        comerciante.
                      </p>
                    </div>

                    <div className="mb-4">
                      <h5>Retornos</h5>
                      <ul className="mb-0">
                        <li>
                          <Badge color="success">200 OK</Badge> QR Code generado
                          con éxito.
                        </li>
                        <li>
                          <Badge color="danger">401 Unauthorized</Badge> Token
                          inválido o expirado.
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h5>Solicitud</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {`{
    "total_brl": 100,
    "expiracao": 600,
    "pdv_id": 1,
    "atendente_id": 1
}`}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>

                    <div className="mb-0">
                      <h5>Respuesta Exitosa</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {generateSuccessResponse}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Ejemplo cURL con PDV</h5>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => copyToClipboard(curlCodeGenerate)}
                    >
                      <FeatherIcon icon="copy" size={14} />
                    </Button>
                  </div>
                  <Card className="bg-light">
                    <CardBody className="p-0">
                      <SyntaxHighlighter
                        language="bash"
                        style={atomOneDark}
                        customStyle={{
                          padding: "1.5rem",
                          margin: "0",
                        }}
                        wrapLines={true}
                      >
                        {curlCodeGenerate}
                      </SyntaxHighlighter>
                    </CardBody>
                  </Card>

                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Ejemplo cURL sin PDV</h5>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => copyToClipboard(curlCodeGenerateSinpdv)}
                    >
                      <FeatherIcon icon="copy" size={14} />
                    </Button>
                  </div>
                  <Card className="bg-light">
                    <CardBody className="p-0">
                      <SyntaxHighlighter
                        language="bash"
                        style={atomOneDark}
                        customStyle={{
                          padding: "1.5rem",
                          margin: "0",
                        }}
                        wrapLines={true}
                      >
                        {curlCodeGenerateSinpdv}
                      </SyntaxHighlighter>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Buscar Órdenes Pix */}
          <Row className="mb-4">
            <Col xl={8}>
              <Card>
                <CardBody>
                  <div className="live-preview">
                    <div className="d-flex align-items-center mb-4">
                      <div className="flex-grow-1">
                        <h2 className="mb-0">Buscar Órdenes Pix</h2>
                      </div>
                      <div className="flex-shrink-0">
                        <Badge color="primary" className="fs-5">
                          POST
                        </Badge>
                        <br />
                        <small className="text-muted">/ordens-pix/busca</small>
                      </div>
                    </div>

                    <div className="mb-4">
                      <h5>Descripción</h5>
                      <p>
                        Busca órdenes pix según los parámetros proporcionados.
                      </p>
                    </div>

                    <div className="mb-4">
                      <h5>Parámetros</h5>
                      <h6 className="mt-3">Obligatorios:</h6>
                      <ul>
                        <li>
                          <strong>itens_pagina</strong>: Cantidad de ítems por
                          página
                        </li>
                        <li>
                          <strong>pagina</strong>: Número de la página a
                          consultar
                        </li>
                      </ul>
                      <h6 className="mt-3">Opcionales:</h6>
                      <ul>
                        <li>
                          <strong>status</strong>: Estado de la orden. Valores
                          posibles: novo, pago, enviado, invalido, cancelado,
                          processando, finalizada, reembolsado, expirada
                        </li>
                        <li>
                          <strong>order</strong>: Orden de los resultados.
                          Valores posibles: asc, desc
                        </li>
                        <li>
                          <strong>pdv_id</strong>: ID del punto de venta
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h5>Retornos</h5>
                      <ul className="mb-0">
                        <li>
                          <Badge color="success">200 OK</Badge> Órdenes
                          encontradas con éxito.
                        </li>
                        <li>
                          <Badge color="danger">401 Unauthorized</Badge> Token
                          inválido o expirado.
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h5>Solicitud</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {`{
    "itens_pagina": 10,
    "pagina": 1,
    "status": "pago",
    "order": "desc",
    "pdv_id": 28
}`}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>

                    <div className="mb-0">
                      <h5>Respuesta Exitosa</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {searchSuccessResponse}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Ejemplo cURL</h5>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => copyToClipboard(curlCodeSearch)}
                    >
                      <FeatherIcon icon="copy" size={14} />
                    </Button>
                  </div>
                  <Card className="bg-light">
                    <CardBody className="p-0">
                      <SyntaxHighlighter
                        language="bash"
                        style={atomOneDark}
                        customStyle={{
                          padding: "1.5rem",
                          margin: "0",
                        }}
                        wrapLines={true}
                      >
                        {curlCodeSearch}
                      </SyntaxHighlighter>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Cotar */}
          <Row className="mb-4">
            <Col xl={8}>
              <Card>
                <CardBody>
                  <div className="live-preview">
                    <div className="d-flex align-items-center mb-4">
                      <div className="flex-grow-1">
                        <h2 className="mb-0">Cotar</h2>
                      </div>
                      <div className="flex-shrink-0">
                        <Badge color="primary" className="fs-5">
                          POST
                        </Badge>
                        <br />
                        <small className="text-muted">/cotar</small>
                      </div>
                    </div>

                    <div className="mb-4">
                      <h5>Descripción</h5>
                      <p>Calcula la cotización de una moneda específica.</p>
                    </div>

                    <div className="mb-4">
                      <h5>Retornos</h5>
                      <ul className="mb-0">
                        <li>
                          <Badge color="success">200 OK</Badge> Cotización
                          calculada con éxito.
                        </li>
                        <li>
                          <Badge color="danger">401 Unauthorized</Badge> Token
                          inválido o expirado.
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h5>Solicitud</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {`{
    "moeda": "ARS",
    "total": "200000.00",
    "destino": "USDT",
    "pdv_id": "1"
}`}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>

                    <div className="mb-0">
                      <h5>Respuesta Exitosa</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {cotarSuccessResponse}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Ejemplo cURL con PDV</h5>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => copyToClipboard(curlCodeCotar)}
                    >
                      <FeatherIcon icon="copy" size={14} />
                    </Button>
                  </div>
                  <Card className="bg-light">
                    <CardBody className="p-0">
                      <SyntaxHighlighter
                        language="bash"
                        style={atomOneDark}
                        customStyle={{
                          padding: "1.5rem",
                          margin: "0",
                        }}
                        wrapLines={true}
                      >
                        {curlCodeCotar}
                      </SyntaxHighlighter>
                    </CardBody>
                  </Card>

                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Ejemplo cURL sin PDV</h5>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => copyToClipboard(curlCodeCotarSinpdv)}
                    >
                      <FeatherIcon icon="copy" size={14} />
                    </Button>
                  </div>
                  <Card className="bg-light">
                    <CardBody className="p-0">
                      <SyntaxHighlighter
                        language="bash"
                        style={atomOneDark}
                        customStyle={{
                          padding: "1.5rem",
                          margin: "0",
                        }}
                        wrapLines={true}
                      >
                        {curlCodeCotarSinpdv}
                      </SyntaxHighlighter>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Visualizar Orden Pix */}
          <Row>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <div className="live-preview">
                    <div className="d-flex align-items-center mb-4">
                      <div className="flex-grow-1">
                        <h2 className="mb-0">Visualizar Orden Pix</h2>
                      </div>
                      <div className="flex-shrink-0">
                        <Badge color="success" className="fs-5">
                          GET
                        </Badge>
                        <br />
                        <small className="text-muted">/ordem</small>
                      </div>
                    </div>

                    <div className="mb-4">
                      <h5>Descripción</h5>
                      <p>Retorna los detalles de una orden pix específica</p>

                      <h6 className="mt-3">Estados posibles:</h6>
                      <ul className="mb-4">
                        <li>
                          <strong>novo</strong>: Orden recién creada, esperando
                          el pago
                        </li>
                        <li>
                          <strong>pago</strong>: Pago recibido con éxito
                        </li>
                        <li>
                          <strong>enviado</strong>: El valor fue enviado para
                          conversión
                        </li>
                        <li>
                          <strong>invalido</strong>: Orden inválida o con error
                        </li>
                        <li>
                          <strong>cancelado</strong>: Orden cancelada por el
                          usuario o sistema
                        </li>
                        <li>
                          <strong>processando</strong>: En proceso de conversión
                        </li>
                        <li>
                          <strong>finalizada</strong>: Orden completada con
                          éxito
                        </li>
                        <li>
                          <strong>reembolsado</strong>: Pago reembolsado al
                          cliente
                        </li>
                        <li>
                          <strong>expirada</strong>: Orden expirada por tiempo
                          límite excedido
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h5>Retornos</h5>
                      <ul className="mb-0">
                        <li>
                          <Badge color="success">200 OK</Badge> Datos retornados
                          con éxito.
                        </li>
                        <li>
                          <Badge color="danger">401 Unauthorized</Badge> Token
                          inválido o expirado.
                        </li>
                        <li>
                          <Badge color="danger">404 Not Found</Badge> Credencial
                          no encontrada.
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h5>Solicitud (Query Parameters)</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {`{
    "ordem_id": 1
}`}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>

                    <div className="mb-0">
                      <h5>Respuesta Exitosa</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {viewSuccessResponse}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Ejemplo cURL</h5>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => copyToClipboard(curlCodeView)}
                    >
                      <FeatherIcon icon="copy" size={14} />
                    </Button>
                  </div>
                  <Card className="bg-light">
                    <CardBody className="p-0">
                      <SyntaxHighlighter
                        language="bash"
                        style={atomOneDark}
                        customStyle={{
                          padding: "1.5rem",
                          margin: "0",
                        }}
                        wrapLines={true}
                      >
                        {curlCodeView}
                      </SyntaxHighlighter>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default QrCodePix;
