import React, { useState, useEffect } from "react";
import {
  Alert,
  Row,
  Col,
  Label,
  FormGroup,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { NumericFormat } from "react-number-format";
import QRCode from "qrcode.react";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";
import { formatCurrency } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const FormPix = ({ onSubmit, toggle }) => {
  const [loading, setLoading] = useState(false);
  const [loadingQr, setLoadingQr] = useState(false);
  const [valorBrl, setValorBrl] = useState("");
  const [cotacao, setCotacao] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);

  const cotar = async () => {
    if (!valorBrl || valorBrl === "0") {
      toast.error("Ingrese un valor válido");
      return;
    }

    setLoading(true);
    const response = await apiClient.post("/cotar", {
      moeda: "BRL",
      total: parseFloat(valorBrl),
      destino: "USDT",
    });

    if (response.sucesso) {
      setCotacao(response.dados);
    } else {
      if (response.mensagem) toast.error(response.mensagem);
      setCotacao(null);
    }
    setLoading(false);
  };

  const gerarQr = async () => {
    setLoadingQr(true);
    const response = await apiClient.post("/gerar-qr", {
      total_brl: parseFloat(valorBrl),
      expiracao: 600,
      moeda_final: "USDT",
    });

    if (response.sucesso) {
      setQrCode(response.dados);
      await onSubmit({
        tipo: "pix",
        token_id: 3,
        valor_usd: response.dados.valor_usd,
        ordem_pix_id: response.dados.id,
      });
      setOrderStatus("novo");
      setCountdown(30 * 60);
    } else {
      if (response.mensagem) toast.error(response.mensagem);
    }
    setLoadingQr(false);
  };

  useEffect(() => {
    let timer;
    if (countdown && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [countdown]);

  useEffect(() => {
    let intervalId;
    if (qrCode?.id && orderStatus === "novo") {
      checkOrderStatus();
      intervalId = setInterval(checkOrderStatus, 10000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [qrCode, orderStatus]);

  const checkOrderStatus = async () => {
    try {
      const response = await apiClient.get(`/ordem?ordem_id=${qrCode.id}`);
      if (response.sucesso && response.dados?.status !== "novo") {
        setOrderStatus(response.dados.status);
        setCountdown(null);
      }
    } catch (error) {
      console.error("Error checking order status:", error);
    }
  };

  const handleCopyQrCode = async () => {
    try {
      await navigator.clipboard.writeText(qrCode.qrcode);
      setShowCopyTooltip(true);
      setTimeout(() => setShowCopyTooltip(false), 1500);
      toast.success("Código PIX copiado con éxito");
    } catch (error) {
      toast.error("Error al copiar el código PIX");
    }
  };

  const formatCountdown = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  if (qrCode) {
    if (orderStatus === "novo") {
      return (
        <div className="text-center">
          <Alert color="info" className="mb-4">
            <p className="mb-0">
              Abra la aplicación de su banco y escanee el código QR para realizar el pago PIX.
            </p>
          </Alert>
          
          <Row className="justify-content-center">
            <Col xs="12" sm="10" md="8" lg="6">
              <div className="d-inline-block bg-white rounded p-4 mb-4">
                <QRCode 
                  value={qrCode.qrcode} 
                  size={window.innerWidth < 576 ? 200 : window.innerWidth < 768 ? 250 : 300} 
                />
              </div>
            </Col>
          </Row>

          <div className="mb-4">
            <Button
              id="copyQrButton"
              color="primary"
              onClick={handleCopyQrCode}
            >
              <FeatherIcon icon="copy" size={16} className="me-2" />
              PIX Copia y Pega
            </Button>
            <UncontrolledTooltip
              placement="top"
              target="copyQrButton"
              isOpen={showCopyTooltip}
            >
              ¡Copiado!
            </UncontrolledTooltip>
          </div>

          {countdown > 0 && (
            <div>
              <small className="text-muted">Tiempo restante</small>
              <h4 className="mb-0">{formatCountdown(countdown)}</h4>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <Alert color="success" className="mb-4">
            <h4 className="alert-heading">¡Depósito en Proceso!</h4>
            <p className="mb-0">
              Su depósito está siendo procesado. El valor estará disponible en su saldo pronto.
            </p>
          </Alert>
          <Button color="primary" onClick={toggle}>
            Cerrar
          </Button>
        </div>
      );
    }
  }

  return (
    <div>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label className="form-label">Token</Label>
            <div className="form-control-plaintext">
              <strong>USDT Arbitrum</strong>
            </div>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label className="form-label">Valor en BRL</Label>
            <NumericFormat
              className="form-control"
              placeholder="R$ 0,00"
              prefix="R$ "
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              disabled={loading || loadingQr}
              value={valorBrl}
              onValueChange={(values) => {
                const { value } = values;
                setValorBrl(value);
                setCotacao(null);
              }}
            />
          </FormGroup>
        </Col>
      </Row>

      {!cotacao && (
        <div className="text-center mt-4">
          <Button
            color="primary"
            onClick={cotar}
            disabled={!valorBrl || loading || loadingQr}
          >
            {loading ? (
              <i className="bx bx-loader bx-spin me-1" />
            ) : (
              <i className="ri-exchange-dollar-line me-1" />
            )}
            Cotizar
          </Button>
        </div>
      )}

      {cotacao && !qrCode && (
        <>
          <Alert color="info" className="mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <strong>Cotización USD/BRL:</strong>{" "}
                {formatCurrency(cotacao.cotacao, "BRL")}
              </div>
              <div>
                <strong>Total en USDT:</strong>{" "}
                {formatCurrency(cotacao.total_usd, "USD")}
              </div>
            </div>
          </Alert>

          <div className="text-center mt-4">
            <Button
              color="success"
              disabled={loading || loadingQr}
              onClick={gerarQr}
            >
              {loadingQr && <i className="bx bx-loader bx-spin me-2" />}
              Generar QR Code
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default FormPix;
