import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin, converterData, formatCurrency } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const formatTipo = (tipo) => {
  const tipos = {
    pix: "PIX",
    koywe: "Latam",
    cripto: "Crypto",
    nautt: "Nautt",
  };
  return tipos[tipo] || tipo;
};

const getBadgeColor = (tipo) => {
  const colors = {
    pix: "info",
    koywe: "warning",
    cripto: "primary",
    nautt: "success",
  };
  return colors[tipo] || "secondary";
};

const ModalEditar = ({
  modal,
  setModal,
  setRefresh,
  moedas,
  tokens,
  deposito,
}) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (modal && deposito) {
      setStatus(deposito.status);
    }
  }, [modal, deposito]);

  const toggle = () => {
    setStatus("");
    setModal(!modal);
  };

  const editar = async () => {
    setLoading(true);

    const data = {
      deposito_id: deposito.id,
      status: status,
    };

    const response = await apiClient.put("/depositos", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Editar Depósito
      </ModalHeader>
      <ModalBody className="text-left p-5">
        {deposito && (
          <div className="mt-4">
            <Row className="mt-3">
              <Col md="6">
                <FormGroup>
                  <Label className="text-muted">Tipo</Label>
                  <div>
                    <Badge color={getBadgeColor(deposito.tipo)}>
                      {formatTipo(deposito.tipo)}
                    </Badge>
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="text-muted">Valor</Label>
                  <div>
                    <h5 className="mb-0">{formatCurrency(deposito.valor_usd, "USD")} ({deposito.token?.sigla})</h5>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="6">
                <FormGroup>
                  <Label className="text-muted">Fecha</Label>
                  <div>
                    {converterData(deposito.created_at)}
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="text-muted">Estado</Label>
                  <Input
                    type="select"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    disabled={loading}
                  >
                    <option value="novo">Nuevo</option>
                    <option value="invalido">Inválido</option>
                    <option value="cancelado">Cancelado</option>
                    <option value="processando">Procesando</option>
                    <option value="finalizado">Finalizado</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <div className="hstack gap-2 justify-content-end mt-5">
              <Link
                to="#"
                className="btn btn-link link-primary fw-medium"
                onClick={() => toggle()}
                disabled={loading}
              >
                <i className="ri-close-line me-1 align-middle" /> Cancelar
              </Link>
              <Link
                to="#"
                className="btn btn-primary"
                onClick={() => editar()}
                disabled={loading}
              >
                {loading && <i className="bx bx-loader bx-spin me-2" />}
                Guardar
              </Link>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
