import React, { useState, useMemo, useEffect } from "react";
import {
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
  UncontrolledTooltip,
  UncontrolledAlert,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { NumericFormat } from "react-number-format";

const FormCrypto = ({ tokens, onSubmit }) => {
  const [selectedToken, setSelectedToken] = useState("");
  const [valorUsd, setValorUsd] = useState("");
  const [copyTooltip, setCopyTooltip] = useState("");

  const filteredTokens = useMemo(() => {
    console.log("Tokens recebidos:", tokens);
    return tokens.filter(
      (token) =>
        token.sigla === "USDT" &&
        token.carteiras_deposito &&
        token.carteiras_deposito.length > 0
    );
  }, [tokens]);

  const selectedTokenData = useMemo(() => {
    console.log("Selected token:", selectedToken);
    console.log("Filtered tokens:", filteredTokens);
    return filteredTokens.find((t) => String(t.id) === String(selectedToken));
  }, [selectedToken, filteredTokens]);

  const handleCopyWallet = (carteira, index) => {
    navigator.clipboard.writeText(carteira.carteira).then(() => {
      setCopyTooltip(`copied-${index}`);
      setTimeout(() => setCopyTooltip(""), 1500);
    });
  };

  const handleSubmit = async() => {
    await onSubmit({
      tipo: "cripto",
      token_id: selectedToken,
      valor_usd: valorUsd,
    });
  };

  return (
    <div>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label className="form-label">Token</Label>
            <Input
              type="select"
              value={selectedToken}
              onChange={(e) => setSelectedToken(e.target.value)}
            >
              <option value="">Seleccione un token</option>
              {filteredTokens.map((token) => (
                <option key={token.id} value={token.id}>
                  {token.nome} ({token.sigla} - {token.rede.nome})
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label className="form-label">Valor en USD</Label>
            <NumericFormat
              id="valor-usd"
              placeholder="$ 0.00"
              className="form-control"
              value={valorUsd}
              onValueChange={({ value }) => setValorUsd(value)}
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              prefix="$ "
              fixedDecimalScale={true}
              allowNegative={false}
            />
          </FormGroup>
        </Col>
      </Row>

      {selectedTokenData && selectedTokenData.carteiras_deposito && (
        <Card className="mt-4">
          <CardBody>
            {selectedTokenData.carteiras_deposito.map((carteira, index) => (
              <div key={index} className="mb-3">
                <Label className="form-label">
                  Wallet para Depósito {selectedTokenData.rede?.nome}
                </Label>
                <div className="d-flex align-items-center">
                  <div
                    className="flex-grow-1 text-break"
                    style={{ fontFamily: "monospace" }}
                  >
                    {carteira.carteira}
                  </div>
                  <div className="ms-2" style={{ cursor: "pointer" }}>
                    <FeatherIcon
                      icon="copy"
                      size={16}
                      id={`copy-wallet-${index}`}
                      onClick={() => handleCopyWallet(carteira, index)}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`copy-wallet-${index}`}
                    >
                      {copyTooltip === `copied-${index}`
                        ? "¡Copiado!"
                        : "Copiar"}
                    </UncontrolledTooltip>
                  </div>
                </div>
              </div>
            ))}
            <UncontrolledAlert color="warning" className="mt-2">
              <div className="d-flex align-items-center">
                <FeatherIcon icon="alert-triangle" size={18} className="me-2" />
                <div>
                  <strong>¡Atención!</strong> El valor que ingrese debe ser
                  EXACTAMENTE el mismo que enviará a la wallet.
                </div>
              </div>
            </UncontrolledAlert>
          </CardBody>
        </Card>
      )}

      <div className="hstack gap-2 justify-content-end mt-4">
        <button className="btn btn-primary" onClick={handleSubmit}>
          Crear Depósito
        </button>
      </div>
    </div>
  );
};

export default FormCrypto;
