import React, { useState, useEffect } from "react";
import { Container, Row, Spinner, Col } from "reactstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  formatCurrency,
  checkLastLogin,
} from "../../../Components/FunctionsRepository";
import { APIClient } from "../../../helpers/api_helper";
import { changeLayoutMode } from "../../../slices/thunks";
import { layoutModeTypes } from "../../../Components/constants/layout";
import CheckoutDetails from "./CheckoutDetails";
import QrCodePix from "./QrCodePix";
import KoywePagamento from "./KoywePagamento";
import UsdPagamento from "./UsdPagamento";

const apiClient = new APIClient();

const CheckoutProdutos = ({ link_id }) => {
  const [loading, setLoading] = useState(true);
  const [calculando, setCalculando] = useState(false);
  const [linkData, setLinkData] = useState(null);
  const [resumoCalculo, setResumoCalculo] = useState(null);
  const [qrCode, setQrCode] = useState("");
  const [linkKoywe, setLinkKoywe] = useState(null);
  const [ordem, setOrdem] = useState(null);
  const [countdown, setCountdown] = useState(0);

  const dispatch = useDispatch();

  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value));
    }
  };

  const fetchLinkData = async () => {
    setLoading(true);
    const response = await apiClient.get(`/links-pagamento/exibir`, {
      link_id: link_id,
    });
    if (response.sucesso) {
      if (response.dados.ordem) {
        const remainingTime = calcularDiferenca(
          response.dados.ordem.created_at
        );
        if (remainingTime > 0) {
          setOrdem(response.dados.ordem);
          setQrCode(response.dados.ordem.qrcode);
          startCountdown(calcularDiferenca(response.dados.ordem.created_at));
        }
      }
      if (response.dados.ordem_koywe) {
        const remainingTime = calcularDiferenca(
          response.dados.ordem_koywe.created_at
        );
        if (remainingTime > 0) {
          setOrdem(response.dados.ordem_koywe);
          setLinkKoywe(response.dados.ordem_koywe.link_pagamento);
          startCountdown(
            calcularDiferenca(response.dados.ordem_koywe.created_at)
          );
        }
      }
      setLinkData(response.dados);
      console.log(response.dados);
      await calcularValor(response.dados);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  };

  const calcularDiferenca = (dataBanco) => {
    // Convert the database timestamp (which is in Brasilia time) to a Date object
    const dbDateBrasilia = new Date(dataBanco);

    // Get current time in UTC
    const now = new Date();

    // Convert both dates to Brasilia time by adjusting for the -3 offset
    const brasiliaOffset = -3 * 60; // -3 hours in minutes
    const nowBrasilia = new Date(
      now.getTime() - (now.getTimezoneOffset() + brasiliaOffset) * 60000
    );

    // Calculate difference in seconds
    const diffInSeconds = Math.floor((nowBrasilia - dbDateBrasilia) / 1000);

    // Return remaining time (10 minutes = 600 seconds)
    return Math.max(1800 - diffInSeconds, 0);
  };

  const calcularValor = async (data) => {
    setCalculando(true);
    if (!data.produtos?.length) {
      // Caso não tenha produtos, monta o resumo diretamente
      setResumoCalculo({
        valor_final: data.valor,
        moeda_sigla: data.moeda?.sigla,
        produtos: [],
        taxas: [],
        cotacao: 1,
      });
      setCalculando(false);
      return;
    }

    let calcData = {
      moeda_id: data.moeda_id,
      user_id: data.user_id,
      token_id: data.token_id,
      produtos: data.produtos.map((p) => ({
        id: p.id,
        quantidade: p.pivot.quantidade,
      })),
    };

    let response = await apiClient.post("/links-pagamento/calcular", calcData);
    if (response.sucesso) {
      let dadosResponse = response.dados;
      const remainingTime = calcularDiferenca(response.dados.created_at);
      if (remainingTime > 0) {
        dadosResponse = {
          ...dadosResponse,
          valor_final: ordem.total_brl,
        };
      }

      calcData = {
        amount: dadosResponse.valor_total,
        moeda_id: data.moeda_id,
        user_id: data.user_id
      };
      response = await apiClient.post("/compras/cotacao", calcData);
      if (response.sucesso) {
        const dadosCotacao = response.dados;

        let valorFinal;
        let taxas;

        valorFinal = dadosResponse.valor_total;
        taxas = valorFinal * dadosCotacao.taxa;

        const dadosResumo = {
          ...dadosResponse,
          valor_final: data.valor,
          cotacao: dadosCotacao.cotacao,
          taxas: taxas,
        };
        setResumoCalculo(dadosResumo);
      } else {
        if (response.mensagem) toast.error(response.mensagem);
      }
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setCalculando(false);
  };

  const startCountdown = (duration) => {
    setCountdown(duration);
  };

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [countdown]);

  const refreshOrdemData = async (ordemData) => {
    const params = {
      ordem_id: ordemData.id,
    };
    const response = ordemData.link_pagamento
      ? await apiClient.get(`/ordem-koywe`, params)
      : await apiClient.get(`/ordem`, params);
    if (response.sucesso) {
      if (response.dados.status !== "novo") {
        setOrdem(null);
        let dadosLink = linkData;
        setLinkData(dadosLink);
        if (ordemData.link_pagamento) {
          dadosLink.ordem_koywe = null;
          setLinkKoywe("");
        } else {
          setQrCode("");
          dadosLink.ordem = null;
        }
        return;
      }
      const remainingTime = calcularDiferenca(response.dados.created_at);
      console.log("Remaining time:", remainingTime);
      if (remainingTime > 0) {
        setOrdem(response.dados);
        console.log("Ordem atualizada:", response.dados);
      } else {
        setOrdem(null);
        let dadosLink = linkData;
        dadosLink.ordem = null;
        setLinkData(dadosLink);
        if (ordemData.link_pagamento) {
          setLinkKoywe("");
          dadosLink.ordem_koywe = null;
        } else {
          setQrCode("");
          dadosLink.ordem = null;
        }
      }
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      onChangeLayoutMode(layoutModeTypes.LIGHTMODE);
      await fetchLinkData();
    };
    if (link_id) {
      fetchAll();
    }
  }, [link_id]);

  useEffect(() => {
    let interval;
    if (qrCode !== "" && linkKoywe !== "") {
      interval = setInterval(() => {
        refreshOrdemData(ordem);
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [qrCode, ordem, linkKoywe]);

  document.title = "Check-Nautt | Nautt Finance";

  if (loading || !linkData) {
    return (
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center mt-4 pt-2 text-center">
            <Spinner color="primary" />
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <Container fluid className="page-content">
      <Row>
        <CheckoutDetails
          loading={loading || calculando}
          linkData={linkData}
          resumoCalculo={resumoCalculo}
          formatCurrency={formatCurrency}
        />

        {linkData?.moeda?.sigla === "BRL" ? (
          <QrCodePix
            loading={loading}
            setLoading={setLoading}
            qrCode={qrCode}
            countdown={countdown}
            ordem={ordem}
            resumoCalculo={resumoCalculo}
            formatCurrency={formatCurrency}
            linkData={linkData}
            setQrCode={setQrCode}
            setOrdem={setOrdem}
            setCountdown={setCountdown}
            startCountdown={startCountdown}
          />
        ) : linkData?.moeda?.sigla === "USD" ? (
          <UsdPagamento
            loading={loading}
            countdown={countdown}
            ordem={ordem}
            resumoCalculo={resumoCalculo}
            formatCurrency={formatCurrency}
            linkData={linkData}
            setOrdem={setOrdem}
            setCountdown={setCountdown}
            startCountdown={startCountdown}
          />
        ) : (
          <KoywePagamento
            loading={loading}
            countdown={countdown}
            ordem={ordem}
            resumoCalculo={resumoCalculo}
            formatCurrency={formatCurrency}
            linkData={linkData}
            setOrdem={setOrdem}
            setLinkKoywe={setLinkKoywe}
            linkKoywe={linkKoywe}
            setCountdown={setCountdown}
            startCountdown={startCountdown}
          />
        )}
      </Row>
    </Container>
  );
};

export default CheckoutProdutos;
