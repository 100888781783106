import React, { useState, useCallback, useEffect } from "react";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import {
  Row,
  Col,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  Spinner,
  Alert,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { formatCurrency } from "../../Components/FunctionsRepository";
import { NumericFormat } from "react-number-format";

const apiClient = new APIClient();

const GerarLinkPagamento = ({
  pdv_id,
  atendente_id,
  pin,
  setRefresh,
  setOcultar,
}) => {
  const [loading, setLoading] = useState(false);
  const [calculando, setCalculando] = useState(false);
  const [loadTable, setLoadTable] = useState(false);
  const [linkGerado, setLinkGerado] = useState(null);

  // Lists for dropdowns
  const [moedas, setMoedas] = useState([]);
  const [moedasConversao, setMoedasConversao] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [descricao, setDescricao] = useState("");
  const [incluirProdutos, setIncluirProdutos] = useState(false);
  const [valorCobrar, setValorCobrar] = useState("");
  const [valorCobrarUSD, setValorCobrarUSD] = useState(0);
  const [cotacao, setCotacao] = useState(0);
  const [moedaConversao, setMoedaConversao] = useState("");
  const [moedaSelecionada, setMoedaSelecionada] = useState("");
  const [tokenSelecionado, setTokenSelecionado] = useState(null);
  const [produtosSelecionados, setProdutosSelecionados] = useState([]);

  // Search and pagination
  const [termoBusca, setTermoBusca] = useState("");
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDeProdutos, setTotalDeProdutos] = useState(0);
  const itensPorPagina = 5;

  // Calculation result
  const [resumoCalculo, setResumoCalculo] = useState(null);

  const fetchMoedas = useCallback(async (pdvId) => {
    const response = await apiClient.get("/moedas");
    if (response.sucesso) {
      const moedasResponse = response.dados;
      //filtrar moedas e retirar USD e ARS da lista
      const moedasFiltradas = moedasResponse.filter(
        (moeda) => moeda.sigla !== "USD"
      );
      setMoedas(moedasFiltradas || []);
      setMoedasConversao(moedasResponse);
    } else {
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
  }, []);

  const fetchTokens = useCallback(async () => {
    const response = await apiClient.get("/tokens");
    if (response.sucesso) {
      const tokens = response.dados || [];
      setTokens(tokens);

      const usdtArbitrum = tokens.find(
        (token) =>
          token.sigla === "USDT" &&
          token.rede?.nome?.toLowerCase().includes("arbitrum")
      );

      if (usdtArbitrum) {
        setTokenSelecionado(usdtArbitrum);
      } else {
        toast.error("Token USDT en la red Arbitrum no encontrado");
      }
    } else {
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
  }, []);

  const fetchProdutos = useCallback(
    async (pdvId, atendenteId) => {
      setLoadTable(true);
      const data = {
        itens_pagina: itensPorPagina,
        pagina: paginaAtual,
        termo: termoBusca,
        order_by: "created_at",
        order: "desc",
        pdv_id: pdvId,
        atendente_id: atendenteId,
      };

      const response = await apiClient.post("/produtos/busca", data);
      if (response.sucesso) {
        const dados = response.dados;
        setProdutos(dados.data);
        setTotalDeProdutos(dados.total);
      } else {
        if (response.mensagem !== "") toast.error(response.mensagem);
      }
      setLoadTable(false);
    },
    [paginaAtual, termoBusca]
  );

  useEffect(() => {
    const fetchData = async (pdvId, atendenteId) => {
      await Promise.all([
        fetchMoedas(pdvId),
        fetchTokens(),
        fetchProdutos(pdvId, atendenteId),
      ]);
    };
    fetchData(pdv_id, atendente_id);
  }, [fetchMoedas, fetchTokens, fetchProdutos, pdv_id, atendente_id]);

  useEffect(() => {
    setResumoCalculo(null);
  }, [moedaSelecionada, moedaConversao]);

  const toggleProdutoSelecionado = (produto) => {
    if (produtosSelecionados.find((p) => p.id === produto.id)) {
      setProdutosSelecionados(
        produtosSelecionados.filter((p) => p.id !== produto.id)
      );
    } else {
      setProdutosSelecionados([
        ...produtosSelecionados,
        { ...produto, quantidade: 1 },
      ]);
    }
  };

  const alterarQuantidade = (produtoId, incremento) => {
    if (incremento !== 1 && incremento !== -1) return;

    setProdutosSelecionados(
      produtosSelecionados
        .map((produto) => {
          if (produto.id === produtoId) {
            const novaQuantidade = produto.quantidade + incremento;
            return novaQuantidade === 0
              ? null
              : {
                  ...produto,
                  quantidade: novaQuantidade,
                };
          }
          return produto;
        })
        .filter(Boolean)
    );
  };

  const alterarQuantidadeManual = (produtoId, valor) => {
    setProdutosSelecionados(
      produtosSelecionados.map((produto) => {
        if (produto.id === produtoId) {
          const novaQuantidade = parseInt(valor) || 1;
          return {
            ...produto,
            quantidade: novaQuantidade >= 1 ? novaQuantidade : 1,
          };
        }
        return produto;
      })
    );
  };

  const calcular = async (pdvId) => {
    if (
      !moedaSelecionada ||
      !tokenSelecionado ||
      (incluirProdutos && produtosSelecionados.length === 0) ||
      (!incluirProdutos && !valorCobrar && !moedaConversao)
    ) {
      toast.error("Por favor, seleccione la moneda y al menos un producto");
      return;
    }

    const userData = await getLoggedinUser();
    if (!userData) {
      toast.error("Error al obtener los datos del usuario");
      return;
    }

    setCalculando(true);
    let data = {
      moeda_id: moedaSelecionada,
      token_id: tokenSelecionado.id,
      produtos: produtosSelecionados.map((p) => ({
        id: p.id,
        quantidade: p.quantidade,
      })),
      pdv_id: pdvId,
      user_id: userData.id,
    };

    if (!incluirProdutos) {
      data.converter_id = moedaConversao;
      data.total = parseFloat(valorCobrar);
    }

    try {
      let response = await apiClient.post("/links-pagamento/calcular", data);
      if (response.sucesso) {
        const dadosCalculo = response.dados;
        setValorCobrarUSD(dadosCalculo.valor_total_usd);

        const data2 = {
          amount: dadosCalculo.valor_total,
          moeda_id: moedaSelecionada,
        };
        response = await apiClient.post("/compras/cotacao", data2);
        if (response.sucesso) {
          const dadosCotacao = response.dados;

          let valorFinal;
          let taxas;

          valorFinal = dadosCalculo.valor_total;
          if (!incluirProdutos) {
            valorFinal =
              moedaConversao === moedaSelecionada
                ? parseFloat(valorCobrar)
                : dadosCalculo.valor_total;
          }
          taxas = valorFinal * dadosCotacao.taxa;

          const dadosResumo = {
            ...dadosCalculo,
            valor_final: valorFinal,
            cotacao: dadosCotacao.cotacao,
            taxas: taxas,
          };

          setCotacao(valorFinal / dadosCalculo.valor_total_usd);
          console.log(dadosResumo);
          setResumoCalculo(dadosResumo);
        } else {
          if (response.mensagem) toast.error(response.mensagem);
        }
      } else {
        if (response.mensagem) toast.error(response.mensagem);
      }
    } catch (error) {
      console.error("Erro ao calcular:", error);
      toast.error("Error al calcular los valores");
    }

    setCalculando(false);
  };

  const gerarLink = async (
    pdvPin,
    atendenteId,
    pdvId,
    valor,
    produtosSelecionados
  ) => {
    if (!moedaSelecionada) {
      toast.error("Seleccione una moneda");
      return;
    }

    if (incluirProdutos) {
      if (produtosSelecionados.length === 0) {
        toast.error("Seleccione al menos un producto");
        return;
      }
    }

    const dadosLink = {
      moeda_id: moedaSelecionada,
      token_id: tokenSelecionado.id,
      descricao,
      pdv_id: pdvId === "0" || pdvId === 0 ? null : pdvId,
      produtos: produtosSelecionados,
      atendente_id:
        atendenteId === "0" || atendenteId === 0 ? null : atendenteId,
      pin: pdvPin,
      valor,
    };

    try {
      setLoading(true);
      const response = await apiClient.post("/links-pagamento", dadosLink);
      if (response.sucesso) {
        toast.success(response.mensagem);
        setLinkGerado(response.dados);
        if (setRefresh) setRefresh(true);
        if (setOcultar) setOcultar(true);
      } else {
        if (response.mensagem) toast.error(response.mensagem);
      }
    } catch (error) {
      console.error("Erro ao gerar link:", error);
      toast.error("Error al generar el link de pago");
    } finally {
      setLoading(false);
    }
  };

  const copiarLink = () => {
    if (linkGerado?.link) {
      navigator.clipboard.writeText(
        `https://painel.nautt.finance/check-nautt/${linkGerado.link}`
      );
      toast.success("¡Enlace copiado al portapapeles!");
    }
  };

  if (linkGerado) {
    return (
      <Card className="shadow-sm border-light">
        <CardBody>
          <h5 className="card-title mb-3 text-success">
            <FeatherIcon icon="link" size={20} className="me-2" />
            Link de Pagamento Generado
          </h5>
          <div className="alert alert-success">
            <p className="mb-2">
              <strong>Enlace UUID:</strong> {linkGerado.link}
            </p>
            <p className="mb-2">
              <strong>Valor:</strong>{" "}
              {formatCurrency(linkGerado.valor, linkGerado.moeda?.sigla)}
            </p>
            {linkGerado.descricao && (
              <p className="mb-0">
                <strong>Descripción:</strong> {linkGerado.descricao}
              </p>
            )}
          </div>

          {linkGerado.produtos?.length > 0 && (
            <div className="mt-4">
              <h6 className="mb-3">Productos Seleccionados:</h6>
              <div className="table-responsive">
                <table className="table table-borderless mb-0">
                  <thead>
                    <tr>
                      <th>Producto</th>
                      <th>Cantidad</th>
                    </tr>
                  </thead>
                  <tbody>
                    {linkGerado.produtos?.map((produto) => (
                      <tr key={produto.id}>
                        <td>{produto.nome}</td>
                        <td>{produto.pivot.quantidade}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className="mt-4">
            <Button
              color="success"
              onClick={copiarLink}
              className="d-flex align-items-center"
            >
              <FeatherIcon icon="copy" size={16} className="me-2" />
              Copiar Enlace
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <div className="payment-link-generator">
      <Row className="g-3">
        <Col md="6">
          <Label className="form-label fw-bold">Moneda a Cobrar</Label>
          <Input
            type="select"
            value={moedaSelecionada}
            onChange={(e) => setMoedaSelecionada(e.target.value)}
            required
            className="form-select shadow-sm"
          >
            <option value="">Seleccione una moneda</option>
            {moedas
              .filter(
                (moeda) =>
                  moeda.sigla !== "USD" &&
                  moeda.sigla !== "ARS" &&
                  moeda.sigla !== "COP"
              )
              .map((moeda) => (
                <option key={moeda.id} value={moeda.id}>
                  {moeda.nome} ({moeda.sigla})
                </option>
              ))}
          </Input>
        </Col>
        <Col md="6">
          <Label className="form-label fw-bold">Token</Label>
          <div className="position-relative">
            <Input
              type="text"
              value={
                tokenSelecionado
                  ? `${tokenSelecionado.nome} (${tokenSelecionado.sigla}) - ${tokenSelecionado.rede?.nome}`
                  : "Cargando..."
              }
              readOnly
              className="form-control shadow-sm"
            />
            <FeatherIcon
              icon="link"
              size={18}
              className="position-absolute top-50 end-0 translate-middle-y me-3 text-muted"
            />
          </div>
        </Col>
      </Row>

      <Row className="mt-4 g-3">
        <Col md="12">
          <Label className="form-label fw-bold">Descripción</Label>
          <Input
            type="textarea"
            placeholder="Descripción para el cliente..."
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            className="form-control shadow-sm"
            maxLength={255}
          />
          {!incluirProdutos && (
            <span className="text-muted small">
              Esta descripción aparecerá en el enlace de pago para el cliente
              final.
            </span>
          )}
        </Col>
      </Row>

      <Row className="mt-4 g-3">
        <Col md="12">
          <div className="form-check mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="incluirProdutos"
              checked={incluirProdutos}
              onChange={(e) => setIncluirProdutos(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="incluirProdutos">
              ¿Incluir Productos?
            </label>
          </div>

          {!incluirProdutos ? (
            <Row className="mt-3 mb-3">
              <Col md="6">
                <label htmlFor="moedaConversao" className="form-label">
                  Moneda de Referencia
                </label>
                <select
                  id="moedaConversao"
                  value={moedaConversao}
                  onChange={(e) => setMoedaConversao(e.target.value)}
                  className="form-select shadow-sm"
                >
                  <option value="">Seleccione una moneda</option>
                  {moedasConversao.map((moeda) => (
                    <option key={moeda.id} value={moeda.id}>
                      {moeda.nome} ({moeda.sigla})
                    </option>
                  ))}
                </select>
              </Col>
              <Col md="6">
                <label htmlFor="valorCobrar" className="form-label">
                  Valor a Cobrar (
                  {moedaConversao
                    ? moedas.find((m) => m.id === parseInt(moedaConversao))
                        ?.sigla
                    : ""}
                  )
                </label>

                <NumericFormat
                  value={valorCobrar}
                  onValueChange={(values) => {
                    setValorCobrar(values.value);
                    setResumoCalculo(null);
                  }}
                  className="form-control"
                  placeholder="0.00"
                  allowNegative={false}
                  decimalScale={2}
                  decimalSeparator="."
                  thousandSeparator=","
                />
              </Col>

              <small className="text-muted">
                Elija la Moneda y cuanto quieres cobrar para el sistema Nautt
                realizar la conversión para la Moneda a Cobrar.
              </small>
            </Row>
          ) : (
            <Row className="mt-4 g-3">
              <Col md="12">
                <Label className="form-label fw-bold">Buscar Productos</Label>
                <div className="position-relative">
                  <Input
                    type="text"
                    placeholder="Digite para buscar productos..."
                    value={termoBusca}
                    onChange={(e) => {
                      setTermoBusca(e.target.value);
                      setPaginaAtual(1);
                    }}
                    className="form-control shadow-sm"
                  />
                  <FeatherIcon
                    icon="search"
                    size={18}
                    className="position-absolute top-50 end-0 translate-middle-y me-3 text-muted"
                  />
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      {incluirProdutos && (
        <Row className="mt-3">
          <Col md="12">
            <div
              className="table-responsive rounded-3"
              style={{
                maxHeight: "300px",
                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                border: "1px solid #e9ecef",
              }}
            >
              {loadTable ? (
                <div className="text-center my-3">
                  <Spinner color="success" size="sm" />
                </div>
              ) : (
                <table className="table table-hover table-striped mb-0">
                  <thead className="table-light sticky-top">
                    <tr>
                      <th style={{ width: "50px" }}></th>
                      <th>Producto</th>
                      <th>Descripción</th>
                      <th>Valor</th>
                      <th>Moneda</th>
                    </tr>
                  </thead>
                  <tbody>
                    {produtos.map((produto) => (
                      <tr
                        key={produto.id}
                        onClick={() => toggleProdutoSelecionado(produto)}
                        style={{
                          cursor: "pointer",
                          transition: "background-color 0.2s ease",
                        }}
                        className={
                          produtosSelecionados.find((p) => p.id === produto.id)
                            ? "table-success"
                            : "hover-highlight"
                        }
                      >
                        <td>
                          <Input
                            type="checkbox"
                            checked={
                              produtosSelecionados.find(
                                (p) => p.id === produto.id
                              )
                                ? true
                                : false
                            }
                            onChange={() => {}}
                          />
                        </td>
                        <td>{produto.nome}</td>
                        <td>{produto.descricao}</td>
                        <td>
                          {formatCurrency(produto.valor, produto.moeda?.sigla)}
                        </td>
                        <td>{produto.moeda?.sigla}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            {totalDeProdutos > itensPorPagina && (
              <div className="d-flex justify-content-center mt-3 align-items-center">
                <Button
                  color="soft-success"
                  outline
                  onClick={() => setPaginaAtual((p) => Math.max(1, p - 1))}
                  disabled={paginaAtual === 1}
                  size="sm"
                  className="me-2"
                >
                  <FeatherIcon icon="chevron-left" size={14} />
                </Button>
                <div className="text-muted small">
                  Página {paginaAtual} de{" "}
                  {Math.ceil(totalDeProdutos / itensPorPagina)}
                </div>
                <Button
                  color="soft-success"
                  outline
                  onClick={() =>
                    setPaginaAtual((p) =>
                      Math.min(
                        Math.ceil(totalDeProdutos / itensPorPagina),
                        p + 1
                      )
                    )
                  }
                  disabled={
                    paginaAtual >= Math.ceil(totalDeProdutos / itensPorPagina)
                  }
                  size="sm"
                  className="ms-2"
                >
                  <FeatherIcon icon="chevron-right" size={14} />
                </Button>
              </div>
            )}
          </Col>
        </Row>
      )}

      {produtosSelecionados.length > 0 && (
        <Row className="mt-4">
          <Col md="12">
            <Card className="shadow-sm border-light">
              <CardBody>
                <h5 className="card-title mb-3 text-success">
                  <FeatherIcon
                    icon="shopping-cart"
                    size={20}
                    className="me-2"
                  />
                  Productos Seleccionados
                </h5>
                <div className="table-responsive">
                  <table className="table table-sm table-borderless">
                    <thead className="table-light">
                      <tr>
                        <th>Producto</th>
                        <th>Valor</th>
                        <th>Moneda</th>
                        <th>Cantidad</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {produtosSelecionados.map((produto) => (
                        <tr key={produto.id} className="align-middle">
                          <td>{produto.nome}</td>
                          <td>
                            {formatCurrency(
                              produto.valor,
                              produto.moeda?.sigla
                            )}
                          </td>
                          <td>{produto.moeda?.sigla}</td>
                          <td style={{ width: "180px" }}>
                            <div className="d-flex align-items-center gap-2">
                              <div
                                className="input-group input-group-sm"
                                style={{ width: "100px" }}
                              >
                                <Input
                                  type="number"
                                  min="1"
                                  value={produto.quantidade}
                                  onChange={(e) => {
                                    alterarQuantidadeManual(
                                      produto.id,
                                      e.target.value
                                    );
                                    setResumoCalculo(null);
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                  className="form-control form-control-sm text-center shadow-sm"
                                />
                              </div>
                              <div className="hstack gap-1">
                                <Button
                                  color="soft-success"
                                  size="sm"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    alterarQuantidade(produto.id, -1);
                                    setResumoCalculo(null);
                                  }}
                                  disabled={produto.quantidade < 1}
                                  outline
                                >
                                  <FeatherIcon icon="minus" size={14} />
                                </Button>
                                <Button
                                  color="soft-success"
                                  size="sm"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    alterarQuantidade(produto.id, 1);
                                    setResumoCalculo(null);
                                  }}
                                  outline
                                >
                                  <FeatherIcon icon="plus" size={14} />
                                </Button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      {resumoCalculo && (
        <Row className="mt-3">
          {valorCobrarUSD < 100 && (
            <Col md="12" className="mb-2">
              <Alert color="warning" className="mt-3">
                Tenga en cuenta que para valores inferiores a 100 USD, el cambio
                puede ser menos favorable de lo habitual.
              </Alert>
            </Col>
          )}
          <Col md="12">
            <Card className="shadow-sm border-light">
              <CardBody>
                <h5 className="card-title mb-1 text-success">
                  <FeatherIcon icon="info" size={20} className="me-2" />
                  Resumen del Cálculo *
                </h5>
                <span className="text-muted small">
                  (*) El precio apresentado es el final a ser pago por tu
                  cliente y incluye tasas operativas y impositivas.
                </span>
                <div className="table-responsive mt-2">
                  <table className="table table-sm table-borderless">
                    <tbody>
                      <tr>
                        <th className="text-success">Valor Total:</th>
                        <td className="fw-bold">
                          ~{" "}
                          {formatCurrency(
                            resumoCalculo.valor_final,
                            resumoCalculo.moeda_sigla
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-success">Cotação USD:</th>
                        <td className="fw-bold">
                          {formatCurrency(
                            resumoCalculo.cotacao,
                            resumoCalculo.moeda_sigla
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-success">Valor Total (USD):</th>
                        <td className="fw-bold">
                          ~{" "}
                          {formatCurrency(
                            resumoCalculo.valor_final / resumoCalculo.cotacao,
                            "USD"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      <div className="hstack gap-2 justify-content-end mt-4">
        {!resumoCalculo ? (
          <Button
            color="danger"
            onClick={() => calcular(pdv_id)}
            disabled={
              loading ||
              calculando ||
              !moedaSelecionada ||
              !tokenSelecionado ||
              (incluirProdutos && produtosSelecionados.length === 0)
            }
            className="d-flex align-items-center"
          >
            {calculando ? (
              <Spinner size="sm" className="me-2" />
            ) : (
              <FeatherIcon icon="dollar-sign" size={16} className="me-2" />
            )}
            {calculando ? "Calculando..." : "Calcular"}
          </Button>
        ) : (
          <Button
            color="success"
            onClick={() =>
              gerarLink(
                pin,
                atendente_id,
                pdv_id,
                resumoCalculo.valor_final,
                produtosSelecionados
                  .filter((p) => p.quantidade > 0)
                  .map((p) => ({
                    id: p.id,
                    quantidade: p.quantidade,
                  }))
              )
            }
            disabled={loading || calculando}
            className="d-flex align-items-center"
          >
            {loading ? (
              <Spinner size="sm" className="me-2" />
            ) : (
              <FeatherIcon icon="link" size={16} className="me-2" />
            )}
            {loading ? "Generando..." : "Generar Link"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default GerarLinkPagamento;
