// Função para verificar o último horário de login
export const checkLastLogin = async () => {
  const lastLoginTimestamp = localStorage.getItem("lastLogin");
  if (lastLoginTimestamp) {
    const lastLoginTime = new Date(parseInt(lastLoginTimestamp));
    const currentTime = new Date();
    const timeDiffInHours = (currentTime - lastLoginTime) / (1000 * 60 * 60);

    if (timeDiffInHours >= 1) {
      localStorage.removeItem("lastLogin");
      localStorage.removeItem("authUser");
      window.location.reload();
    }
  }
};

export const converterData = (dataISO, minutoSegundo = true) => {
  if (dataISO) {
    // Parse the input date (which is in Brasília timezone)
    const date = new Date(dataISO);
    
    // Format the date components
    const dia = date.getDate().toString().padStart(2, '0');
    const mes = (date.getMonth() + 1).toString().padStart(2, '0');
    const ano = date.getFullYear();
    const hora = date.getHours().toString().padStart(2, '0');
    const minuto = date.getMinutes().toString().padStart(2, '0');

    return minutoSegundo
      ? `${dia}/${mes}/${ano} ${hora}:${minuto}`
      : `${dia}/${mes}/${ano}`;
  }
  return "";
};

export const generateRandomString = () => {
  const letras = "abcdefghijklmnopqrstuvwxyz";
  const numeros = "0123456789";

  let resultado = "";
  for (let i = 0; i < 4; i++) {
    resultado += letras.charAt(Math.floor(Math.random() * letras.length));
  }

  for (let i = 0; i < 4; i++) {
    resultado += numeros.charAt(Math.floor(Math.random() * numeros.length));
  }

  return resultado;
};

export const formatCurrency = (value, currency) => {
  if (value === 0) {
    return currency === "BRL" ? "R$ 0,00" : "$ 0,00";
  }
  if (!value) {
    return "";
  }

  const currencyConfig = {
    BRL: { locale: "pt-BR", symbol: "R$" },
    USD: { locale: "en-US", symbol: "$" },
    ARS: { locale: "es-AR", symbol: "ARS" },
    CLP: { locale: "es-CL", symbol: "CLP" },
    MXN: { locale: "es-MX", symbol: "MX$" },
    COP: { locale: "es-CO", symbol: "COL$" },
    PEN: { locale: "es-PE", symbol: "S/" },
  };

  const config = currencyConfig[currency] || currencyConfig.USD;

  const formattedNumber = new Intl.NumberFormat(config.locale, {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value);

  return `${config.symbol} ${formattedNumber}`;
};

export const formatEmail = (value) => {
  value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

  const atSymbolCount = value.split("@").length - 1;
  if (atSymbolCount > 1) {
    const index = value.lastIndexOf("@");
    value = value.slice(0, index) + value.slice(index + 1);
  }

  return value;
};

export const nomeMask = (nome) => {
  const particulas = [
    "e",
    "de",
    "do",
    "da",
    "dos",
    "das",
    "no",
    "na",
    "nos",
    "nas",
  ];

  const nomeProcessado = nome.replace(/\d/g, "").replace(/\s+/g, " ").trim();

  const palavras = nomeProcessado.split(" ");

  const nomeFormatado = palavras.map((palavra, index) => {
    palavra = palavra.toLowerCase();

    if (particulas.includes(palavra) && index !== 0) {
      return palavra;
    }

    return palavra.charAt(0).toUpperCase() + palavra.slice(1);
  });

  return nomeFormatado.join(" ");
};

// Função de aplicar máscara de telefone com DDD para fixo ou móvel
export const telefoneMask = (telefone) => {
  if (!telefone) return "";
  telefone = telefone.replace(/\D/g, "");
  
  if (telefone.length === 11) {
    telefone = telefone.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
  } else if (telefone.length === 10) {
    telefone = telefone.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
  }
  
  return telefone;
};

//funcao de aplicar mascara de cpf
export const cpfMask = (cpf) => {
  if (!cpf) return "";
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return cpf;
};

//funcao de aplicar mascara de cnpj
export const cnpjMask = (cnpj) => {
  if (!cnpj) return "";
  cnpj = cnpj.replace(/\D/g, "");
  cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
  cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
  return cnpj;
};


export const trataUsename = (input) => {
  const resultado = input
    .replace(/[^A-Za-z0-9_]/g, "")
    .replace(/\s/g, "")
    .toLowerCase()
    .slice(0, 15);
  return resultado;
};


// Função para truncar decimais sem arredondar
export const truncateDecimals = (number, decimals = 2) => {
  const multiplier = Math.pow(10, decimals);
  return Math.floor(number * multiplier) / multiplier;
};