import React, { useState, useEffect, useCallback } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Button, Row, Badge } from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
import Section from "./Section";
import ModalSaque from "./ModalSaque";
import {
  truncateDecimals,
  formatCurrency,
} from "../../Components/FunctionsRepository";
const apiClient = new APIClient();

const Widgets = ({ userData }) => {
  const [selectedCurrency, setSelectedCurrency] = useState("ARS");
  const [currentPage, setCurrentPage] = useState(0);
  const [widgets, setWidgets] = useState([]);
  const [modal, setModal] = useState(false);
  const [fundosUSD, setFundosUSD] = useState(0);
  const [fundosBRL, setFundosBRL] = useState(0);
  const [fundosARS, setFundosARS] = useState(0);
  const [fundosCLP, setFundosCLP] = useState(0);
  const [fundosMXN, setFundosMXN] = useState(0);
  const [fundosCOP, setFundosCOP] = useState(0);
  const [fundosPEN, setFundosPEN] = useState(0);
  const [nauttPontos, setNauttPontos] = useState(0);
  const [cotacoes, setCotacoes] = useState({
    BRL: { compra: 0, venda: 0 },
    ARS: { compra: 0, venda: 0 },
    CLP: { compra: 0, venda: 0 },
    COP: { compra: 0, venda: 0 },
    PEN: { compra: 0, venda: 0 },
    MXN: { compra: 0, venda: 0 },
  });
  const [isFlashing, setIsFlashing] = useState(false);

  const toggleModal = () => setModal(!modal);

  const currencies = ["ARS", "BRL", "COP", "PEN", "CLP", "MXN"];

  const allCurrencies = [
    {
      id: 1,
      label: "Dólar / Real Brasileño",
      moeda: "BRL",
    },
    {
      id: 2,
      label: "Dólar / Peso Argentino",
      moeda: "ARS",
    },
    {
      id: 3,
      label: "Dólar / Peso Chileno",
      moeda: "CLP",
    },
    {
      id: 4,
      label: "Dólar / Peso Colombiano",
      moeda: "COP",
    },
    {
      id: 5,
      label: "Dólar / Sol Peruano",
      moeda: "PEN",
    },
    {
      id: 6,
      label: "Dólar / Peso Mexicano",
      moeda: "MXN",
    },
  ];

  const getCurrentPageCurrencies = () => {
    const startIndex = (currentPage * 3) % allCurrencies.length;
    let currencies = [];
    for (let i = 0; i < 3; i++) {
      const index = (startIndex + i) % allCurrencies.length;
      const currency = allCurrencies[index];
      currencies.push({
        ...currency,
        counter: cotacoes[currency.moeda].compra,
        counter2: cotacoes[currency.moeda].venda,
      });
    }
    return currencies;
  };

  const getWidgetsData = useCallback(async () => {
    const response = await apiClient.get("/widgets", {});
    if (response.sucesso) {
      const dados = response.dados;
      console.log(dados);

      // Atualiza as cotações
      setCotacoes({
        BRL: { compra: dados.brl_usd.compra, venda: dados.brl_usd.venda },
        ARS: { compra: dados.ars_usd.compra, venda: dados.ars_usd.venda },
        CLP: { compra: dados.clp_usd.compra, venda: dados.clp_usd.venda },
        COP: { compra: dados.cop_usd.compra, venda: dados.cop_usd.venda },
        PEN: { compra: dados.pen_usd.compra, venda: dados.pen_usd.venda },
        MXN: { compra: dados.mxn_usd.compra, venda: dados.mxn_usd.venda },
      });

      // Atualiza os widgets com as novas cotações
      setWidgets(getCurrentPageCurrencies());

      // Atualiza os fundos
      const caixaUSD = parseFloat(dados.caixa_usd);
      setFundosUSD(truncateDecimals(caixaUSD).toString());
      setFundosBRL(truncateDecimals(caixaUSD * dados.brl_usd.venda).toString());
      setFundosARS(truncateDecimals(caixaUSD * dados.ars_usd.venda).toString());
      setFundosCLP(truncateDecimals(caixaUSD * dados.clp_usd.venda).toString());
      setFundosMXN(truncateDecimals(caixaUSD * dados.mxn_usd.venda).toString());
      setFundosCOP(truncateDecimals(caixaUSD * dados.cop_usd.venda).toString());
      setFundosPEN(truncateDecimals(caixaUSD * dados.pen_usd.venda).toString());
      setNauttPontos(dados.nautt_pontos);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFlashing(true);
      setCurrentPage((prev) => prev + 1);
      setTimeout(() => setIsFlashing(false), 500); // Remove o efeito após 500ms
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setWidgets(getCurrentPageCurrencies());
  }, [currentPage, cotacoes]);

  const getCurrencyValue = (currency) => {
    switch (currency) {
      case "ARS":
        return fundosARS;
      case "BRL":
        return fundosBRL;
      case "COP":
        return fundosCOP;
      case "PEN":
        return fundosPEN;
      case "CLP":
        return fundosCLP;
      case "MXN":
        return fundosMXN;
      default:
        return fundosARS;
    }
  };

  const handleCurrencyClick = () => {
    const currentIndex = currencies.indexOf(selectedCurrency);
    const nextIndex = (currentIndex + 1) % currencies.length;
    setSelectedCurrency(currencies[nextIndex]);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getWidgetsData();
    };
    const interval = setInterval(fetchData, 30000);
    if (widgets.length === 0) fetchData();

    return () => clearInterval(interval);
  }, []);

  return (
    <React.Fragment>
      <Section nauttPontos={nauttPontos} userData={userData} />
      <Row>
        {widgets.map((item, key) => (
          <Col xl={3} md={6} key={key}>
            <Card
              className={`card-animate ${isFlashing ? "flash-animation" : ""}`}
            >
              <style>
                {`
                  @keyframes flashAnimation {
                    0% { background-color: transparent; }
                    50% { background-color: rgba(255, 255, 190, 0.3); }
                    100% { background-color: transparent; }
                  }
                  .flash-animation {
                    animation: flashAnimation 0.5s ease-out;
                  }
                `}
              </style>
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      {item.label}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <span className="text-success">
                      Compra{" "}
                      <i className="fs-13 align-middle ri-arrow-right-up-line"></i>
                    </span>
                    <h4 className="fs-14 fw-semibold ff-secondary mb-4">
                      <span className="counter-value">
                        {formatCurrency(item.counter, item.moeda)}
                      </span>
                    </h4>
                  </div>
                  <div>
                    <span className="text-danger">
                      Venta{" "}
                      <i className="fs-13 align-middle ri-arrow-right-down-line"></i>
                    </span>
                    <h4 className="fs-16 fw-semibold ff-secondary mb-4">
                      <span className="counter-value">
                        {formatCurrency(item.counter2, item.moeda)}
                      </span>
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
        <Col xl={3} md={6}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Cartera (USD)
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <Badge
                    color="info"
                    pill
                    style={{ cursor: "pointer" }}
                    onClick={handleCurrencyClick}
                  >
                    <i className="bx bx-wallet"></i>{" "}
                    {formatCurrency(
                      getCurrencyValue(selectedCurrency),
                      selectedCurrency
                    )}
                  </Badge>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <span className="text-success">Saldo</span>
                  <h4 className={`fs-16 fw-semibold ff-secondary mb-4`}>
                    <span className="counter-value" data-target="559.25">
                      {formatCurrency(fundosUSD, "USD")}
                    </span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0 p-1">
                  <span className={"avatar-title rounded fs-3 bg-success"}>
                    <i className="bx bx-wallet"></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ModalSaque modal={modal} toggle={toggleModal} />
    </React.Fragment>
  );
};

export default Widgets;
