import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Row, Col, CardFooter } from "reactstrap";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { checkLastLogin, formatCurrency } from "../../Components/FunctionsRepository";
import { toast } from "react-toastify";
import GreatPagination from "../../Components/Common/GreatPagination";
import ModalRelatorio from "./ModalRelatorio";

const apiClient = new APIClient();

const RecentOrders = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [ordens, setOrdens] = useState([]);
  const [totalDeOrdens, setTotalDeOrdens] = useState(0);
  const [itensPorPagina, setItensPorPagina] = useState(5);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [termoBusca, setTermoBusca] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  const fetchOrdens = useCallback(
    async (itensPorPagina, paginaAtual, termoBusca) => {
      const hoje = new Date();
      const trintaDiasAtras = new Date(hoje);
      trintaDiasAtras.setDate(hoje.getDate() - 30);

      const data = {
        itens_pagina: itensPorPagina,
        pagina: paginaAtual,
        termo: termoBusca,
        order: "desc",
        data_inicio: trintaDiasAtras.toISOString().split('T')[0],
        data_fim: hoje.toISOString().split('T')[0]
      };

      const response = await apiClient.post("/relatorios/ordens/busca", data);
      if (response.sucesso) {
        const dados = response.dados;
        setOrdens(dados.data);
        setTotalDeOrdens(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401"))
          if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchOrdens(itensPorPagina, paginaAtual, termoBusca);
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoading(false);
    };
    fetchData();
  }, [fetchOrdens, itensPorPagina, paginaAtual, termoBusca]);

  return (
    <React.Fragment>
      <Col xl={8}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <div className="flex-grow-1">
              <h4 className="card-title mb-0">Órdenes completadas</h4>
              <small className="text-muted">en los últimos 30 días</small>
            </div>
            <div className="flex-shrink-0">
              <button
                type="button"
                className="btn btn-soft-info btn-sm shadow-none"
                onClick={toggleModal}
              >
                <i className="ri-file-list-3-line align-middle"></i> Generar
                Informe
              </button>
            </div>
          </CardHeader>

          <CardBody>
            <div className="table-responsive table-card">
              <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-light">
                  <tr>
                    <th scope="col">Fecha</th>
                    <th scope="col">Orden ID</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Valor USD</th>
                    <th scope="col">Atendente</th>
                    <th scope="col">PDV</th>
                  </tr>
                </thead>
                <tbody>
                  {(ordens || []).map((item, key) => (
                    <tr key={key}>
                      <td>{item.Data}</td>
                      <td>
                        <Link
                          to='#'
                          className="fw-medium link-secondary"
                        >
                          {`${item.Tipo}#${item["Ordem ID"]}`}
                        </Link>
                      </td>
                      <td>{formatCurrency(item["Valor na Moeda"],item.Moeda)}</td>
                      <td>{formatCurrency(item["Valor USD"],"USD")}</td>
                      <td>{item.Atendente || "-"}</td>
                      <td>{item.PDV || "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
          <CardFooter>
            <Row className="d-flex justify-content-end mt-3">
              <GreatPagination
                currentPage={paginaAtual}
                totalPages={Math.ceil(totalDeOrdens / itensPorPagina)}
                onPageChange={(page) => setPaginaAtual(page)}
              />
            </Row>
          </CardFooter>
        </Card>
      </Col>

      <ModalRelatorio isOpen={modalOpen} toggle={toggleModal} />
    </React.Fragment>
  );
};

export default RecentOrders;
