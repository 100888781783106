import React from "react";
import { Container, Row, Col, Card, CardBody, Badge, Button } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";

const Autenticacao = () => {
  document.title = "API Autenticación | Nautt Finance";

  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname.includes("sandbox")) {
      return "https://api-sandbox.nautt.finance/v1";
    } else {
      return "https://api.nautt.finance/v1";
    }
  };

  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Copiado al portapapeles");
    } catch (error) {
      toast.error("Error al copiar al portapapeles");
    }
  };

  const baseUrl = getBaseUrl();

  const curlCode = `curl -i -X POST ${baseUrl}/api-data/gerar-bearer \\
    -H "Content-Type: application/json" \\
    -d '{
        "client_id": "550e8400-e29b-41d4-a716-446655440000",
        "client_secret": "abcDEF123456789abcDEF123456789abcDEF123456789"
    }'`;

  const successResponse = `{
    "mensagem": "Token Bearer generado con éxito",
    "dados": {
        "token": "1|abcdefghijklmnopqrstuvwxyz0123456789"
    }
}`;

  const errorResponse = `{
    "mensagem": "Credenciales inválidas"
}`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Autenticación" pageTitle="API" />
          <Row>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <div className="live-preview">
                    <div className="d-flex align-items-center mb-4">
                      <div className="flex-grow-1">
                        <h2 className="mb-0">Generar Token Bearer</h2>
                      </div>
                      <div className="flex-shrink-0">
                        <Badge color="primary" className="fs-5">
                          POST
                        </Badge>
                        <br />
                        <small className="text-muted">/gerar-bearer</small>
                      </div>
                    </div>

                    <div className="mb-4">
                      <h5>Descripción</h5>
                      <p>
                        Genera un Token Bearer a partir de credenciales de API
                        (client_id y client_secret)
                      </p>
                    </div>

                    <div className="mb-4">
                      <h5>Retornos</h5>
                      <ul className="mb-0">
                        <li>
                          <Badge color="success">200 OK</Badge> Token generado
                          con éxito. Retorna un objeto JSON con el token.
                        </li>
                        <li>
                          <Badge color="danger">401 Unauthorized</Badge>{" "}
                          Credenciales inválidas.
                        </li>
                        <li>
                          <Badge color="warning">
                            422 Unprocessable Entity
                          </Badge>{" "}
                          Datos de entrada inválidos.
                        </li>
                        <li>
                          <Badge color="danger">
                            500 Internal Server Error
                          </Badge>{" "}
                          Error interno del servidor.
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h5>Solicitud</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {`{
    "client_id": "550e8400-e29b-41d4-a716-446655440000",
    "client_secret": "abcDEF123456789abcDEF123456789abcDEF123456789"
}`}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>

                    <div className="mb-4">
                      <h5>Respuesta Exitosa</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {successResponse}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>

                    <div className="mb-0">
                      <h5>Respuesta de Error (Credenciales Inválidas)</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {errorResponse}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Ejemplo cURL</h5>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => copyToClipboard(curlCode)}
                    >
                      <FeatherIcon icon="copy" size={14} />
                    </Button>
                  </div>
                  <Card className="bg-light">
                    <CardBody className="p-0">
                      <SyntaxHighlighter
                        language="bash"
                        style={atomOneDark}
                        customStyle={{
                          padding: "1.5rem",
                          margin: "0",
                        }}
                        wrapLines={true}
                      >
                        {curlCode}
                      </SyntaxHighlighter>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Autenticacao;
