import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkLastLogin,
  converterData,
} from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalEditar = ({ modal, setModal, setRefresh, verificacao }) => {
  const [loading, setLoading] = useState(false);
  const [statusVerificacao, setStatusVerificacao] = useState("");

  const toggle = () => {
    setStatusVerificacao("");
    setModal(!modal);
  };

  const editar = async (verificacao) => {
    setLoading(true);

    const data = {
      status: statusVerificacao,
      verificacao_id: verificacao.id,
    };

    const response = await apiClient.put(`/kyc-status`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (verificacao) {
      setStatusVerificacao(verificacao.status || "");
    }
  }, [verificacao]);

  const isPending = verificacao?.status === "em-andamento";

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        {!isPending ? "Ver Verificación" : "Editar Verificación"} KYC #
        {verificacao?.id}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <Label for="nome">Nombre</Label>
              <Input
                type="text"
                name="nome"
                id="nome"
                value={verificacao?.nome || ""}
                readOnly
              />
            </Col>
            <Col md="6">
              <Label for="sobrenome">Primer Apellido</Label>
              <Input
                type="text"
                name="sobrenome"
                id="sobrenome"
                value={verificacao?.primeiro_sobrenome || ""}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="data-nascimento">Fecha de Nacimiento</Label>
              <Input
                type="text"
                name="data-nascimento"
                id="data-nascimento"
                value={converterData(verificacao?.data_nascimento, false)}
                readOnly
              />
            </Col>
            <Col md="6">
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={verificacao?.email || ""}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="4">
              <Label for="pais-fiscal">País Fiscal</Label>
              <Input
                type="text"
                name="pais-fiscal"
                id="pais-fiscal"
                value={verificacao?.country_code || ""}
                readOnly
              />
            </Col>
            <Col md="4">
              <Label for="documento-fiscal">Documento Fiscal</Label>
              <Input
                type="text"
                name="documento-fiscal"
                id="documento-fiscal"
                value={verificacao?.documento || ""}
                readOnly
              />
            </Col>
            <Col md="4">
              <Label for="telefone">Teléfono</Label>
              <Input
                type="tel"
                name="telefone"
                id="telefone"
                value={verificacao?.telefone || ""}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <Label for="endereco">Dirección</Label>
              <Input
                type="text"
                name="endereco"
                id="endereco"
                value={verificacao?.endereco || ""}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="cidade">Ciudad</Label>
              <Input
                type="text"
                name="cidade"
                id="cidade"
                value={verificacao?.cidade || ""}
                readOnly
              />
            </Col>
            <Col md="6">
              <Label for="estado">Estado</Label>
              <Input
                type="text"
                name="estado"
                id="estado"
                value={verificacao?.provincia || ""}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="cep">Código Postal</Label>
              <Input
                type="text"
                name="cep"
                id="cep"
                value={verificacao?.codigo_postal || ""}
                readOnly
              />
            </Col>
            <Col md="6">
              <Label for="pais-endereco">País de la Dirección</Label>
              <Input
                type="text"
                name="pais-endereco"
                id="pais-endereco"
                value={verificacao?.pais_endereco || ""}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <Label for="status-verificacao">Estado</Label>
              <Input
                type="select"
                name="status-verificacao"
                id="status-verificacao"
                value={statusVerificacao}
                onChange={(e) => setStatusVerificacao(e.target.value)}
              >
                <option value="em-andamento">Pendiente</option>
                <option value="verificado">Verificado</option>
                <option value="negado">Rechazado</option>
              </Input>
            </Col>
          </Row>

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
            <button
              className="btn btn-success"
              onClick={() => editar(verificacao)}
              disabled={loading}
            >
              Editar Estado
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
