import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import FormCrypto from "./FormCrypto";
import FormPix from "./FormPix";
import FormLatam from "./FormLatam";
import classnames from "classnames";

const apiClient = new APIClient();

const ModalNovo = ({ modal, setModal, setRefresh, moedas, tokens }) => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("cripto");

  const toggle = () => {
    setActiveTab("cripto");
    setModal(!modal);
  };

  const criar = async (data) => {
    setLoading(true);

    const response = await apiClient.post(`/depositos`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      if (data.tipo === "cripto") toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader className="modal-title" toggle={toggle}>
        Nuevo Depósito
      </ModalHeader>
      <ModalBody className="text-left p-4">
        <Nav tabs className="nav-tabs-custom">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "cripto" })}
              onClick={() => setActiveTab("cripto")}
              style={{ cursor: "pointer" }}
            >
              Cripto
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "pix" })}
              onClick={() => setActiveTab("pix")}
              style={{ cursor: "pointer" }}
            >
              PIX
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "latam" })}
              onClick={() => setActiveTab("latam")}
              style={{ cursor: "pointer" }}
            >
              LATAM
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} className="mt-4">
          <TabPane tabId="cripto">
            <FormCrypto tokens={tokens} onSubmit={criar} />
          </TabPane>
          <TabPane tabId="pix">
            <FormPix onSubmit={criar} toggle={toggle} />
          </TabPane>
          <TabPane tabId="latam">
            <FormLatam />
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
