import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkLastLogin,
} from "../../Components/FunctionsRepository";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalMudarSenha = ({
  modal,
  setModal,
  setRefresh,
  usuario,
}) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [code2fa, setCode2fa] = useState("");
  const [userDados, setUserDados] = useState(null);
  const inputRefs = useRef([]);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = await getLoggedinUser();
      setUserDados(user);
    };
    fetchUserData();
  }, []);

  const toggle = () => {
    setPassword("");
    setConfirmPassword("");
    setVerificationCode(["", "", "", "", "", ""]);
    setCode2fa("");
    setModal(!modal);
  };

  const handleCodeChange = async (index, value) => {
    if (value.length > 1) value = value[0];
    if (!/^\d*$/.test(value)) return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value !== "") {
      // Move to next input
      if (index < 5) {
        inputRefs.current[index + 1]?.focus();
      } else {
        // If it's the last digit
        const fullCode = newCode.join("");
        if (fullCode.length === 6) {
          setCode2fa(fullCode);
        }
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && verificationCode[index] === "" && index > 0) {
      // Move to previous input and clear it
      const newCode = [...verificationCode];
      newCode[index - 1] = "";
      setVerificationCode(newCode);
      inputRefs.current[index - 1]?.focus();
    }
  };

  const editar = async (usuario) => {
    setLoading(true);

    if (!password || !confirmPassword) {
      toast.error("Ingrese la contraseña y su confirmación");
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Las contraseñas no coinciden");
      setLoading(false);
      return;
    }

    const data = {
      password,
      password_confirmation: confirmPassword,
      user_id: usuario.id,
    };

    if (userDados?.needs_2fa) {
      if (!code2fa) {
        toast.error("El código 2FA es requerido");
        setLoading(false);
        return;
      }
      data.code_2fa = code2fa;
    }

    const response = await apiClient.put(`/usuarios/change-password`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Cambiar Contraseña de {usuario?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="password" className="form-label">
                  Contraseña
                </Label>
                <Input
                  id="password"
                  className="form-control"
                  type="password"
                  placeholder="Contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  maxLength={20}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="confirmPassword" className="form-label">
                  Confirmar Contraseña
                </Label>
                <Input
                  id="confirmPassword"
                  className="form-control"
                  type="password"
                  placeholder="Confirmar Contraseña"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  maxLength={20}
                />
              </div>
            </Col>
          </Row>

          {userDados?.needs_2fa && (
          <div className="mt-4">
            <div className="d-flex justify-content-center gap-2 mb-3">
              {verificationCode.map((digit, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="text"
                  className="form-control text-center fw-bold fs-4"
                  style={{
                    width: "28px",
                    height: "45px",
                    padding: "0.375rem",
                  }}
                  value={digit}
                  onChange={(e) => handleCodeChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  maxLength={1}
                  autoFocus={index === 0}
                />
              ))}
            </div>
            <p className="text-muted text-center">
              Ingrese el código de su aplicación Google Authenticator
            </p>
          </div>
        )}

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-secondary fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
            <button
              className="btn btn-secondary"
              onClick={() => editar(usuario)}
              disabled={loading}
            >
              Cambiar Contraseña
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalMudarSenha;
