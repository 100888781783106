import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import logoSm1 from "../../../assets/images/logo-sm-1.png";
import { Link } from "react-router-dom";

const CheckoutDetails = ({ linkData, resumoCalculo, formatCurrency }) => {
  if (!linkData.produtos?.length) {
    return (
      <Col xl={8}>
        <Card className="product">
          <CardBody>
            <Row className="gy-3">
              <div className="col-sm-auto">
                <div className="avatar-lg bg-light rounded p-1">
                  <img src={logoSm1} alt="" className="img-fluid d-block" />
                </div>
              </div>
              <div className="col-sm">
                <h5 className="fs-14 text-truncate">
                  <Link to="#" className="text-body">
                    Cobro Individual
                  </Link>
                </h5>
                <ul className="list-inline text-muted">
                  <li className="list-inline-item">
                    Descripción:{" "}
                    <span className="fw-medium">{linkData.descricao || "-"}</span>
                  </li>
                </ul>
              </div>
            </Row>
          </CardBody>
          <div className="card-footer">
            <div className="row align-items-center gy-3">
              <div className="col-sm">
                <div className="d-flex flex-wrap my-n1">
                  <div className="text-muted">
                    {resumoCalculo?.moeda_sigla}
                  </div>
                </div>
              </div>
              <div className="col-sm-auto">
                <div className="d-flex align-items-center gap-2 text-muted">
                  <div>Total :</div>
                  <h5 className="fs-14 mb-0">
                    {resumoCalculo && formatCurrency(resumoCalculo.valor_final, resumoCalculo.moeda_sigla)}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Col>
    );
  }

  return (
    <Col xl={8}>
      {linkData.produtos.map((produto, index) => (
        <React.Fragment key={produto.id}>
          <Card className="product">
            <CardBody>
              <Row className="gy-3">
                <div className="col-sm-auto">
                  <div className="avatar-lg bg-light rounded p-1">
                    <img src={logoSm1} alt="" className="img-fluid d-block" />
                  </div>
                </div>
                <div className="col-sm">
                  <h5 className="fs-14 text-truncate">
                    <Link to="#" className="text-body">
                      {produto.nome}
                    </Link>
                  </h5>
                  <ul className="list-inline text-muted">
                    <li className="list-inline-item">
                      Descripción:{" "}
                      <span className="fw-medium">{produto.descricao}</span>
                    </li>
                  </ul>

                  <div className="text-muted">
                    Cantidad: {produto.pivot.quantidade}
                  </div>
                </div>
                <div className="col-sm-auto">
                  <div className="text-lg-end">
                    <p className="text-muted mb-1">Precio unitario ({resumoCalculo?.moeda_sigla}):</p>
                    <h5 className="fs-14">
                      {resumoCalculo?.produtos &&
                        formatCurrency(
                          resumoCalculo.produtos[index]?.preco || 0,
                          resumoCalculo?.moeda_sigla
                        )}
                    </h5>
                  </div>
                </div>
              </Row>
            </CardBody>

            <div className="card-footer">
              <div className="row align-items-center gy-3">
                <div className="col-sm">
                  <div className="d-flex flex-wrap my-n1">
                    <div className="text-muted">
                      {resumoCalculo?.moeda_sigla}
                    </div>
                  </div>
                </div>
                <div className="col-sm-auto">
                  <div className="d-flex align-items-center gap-2 text-muted">
                    <div>Total :</div>
                    <h5 className="fs-14 mb-0">
                      {resumoCalculo?.produtos &&
                        formatCurrency(
                          resumoCalculo.produtos[index]?.preco *
                            produto.pivot.quantidade,
                          resumoCalculo.moeda_sigla
                        )}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </React.Fragment>
      ))}
    </Col>
  );
};

export default CheckoutDetails;
