import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";

const ModalApagar = ({ modal, setModal, setRefresh, verificacao }) => {
  const [loading, setLoading] = useState(false);
  const toggle = () => {
    setRefresh(true);
    setModal(!modal);
  };

  const apagar = async () => {
    setLoading(true);
    const api = new APIClient();

    const params = {
      verificacao_id: verificacao?.id
    }

    const response = await api.delete(
      `/kyc`, params
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      setLoading(false);
      toggle();
    } else {
      toast.error(response.mensagem || "Erro ao apagar a verificação");
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-md"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Eliminar Verificación # {verificacao?.kyc_code || verificacao?.id}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
        <Row className="-flex justify-content-center mt-3">
            <h4 className="text-center text-danger">¿Estás seguro?</h4>
            <div className="text-center">
              Eliminar la <b>verificación # {verificacao?.kyc_code || verificacao?.id}</b> es una acción
              irreversible y no se puede deshacer. Si desea continuar,
              haga clic en el botón <b className="text-danger">Eliminar</b>.
            </div>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Button
              color="danger"
              className="fw-medium"
              onClick={() => apagar()}
            >
              Eliminar
            </Button>
            <Link
              to="#"
              className="btn btn-link link-danger fw-medium"
              onClick={() => toggle()}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalApagar;
