import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, Button, Spinner, Input, Label } from "reactstrap";
import { QRCodeSVG } from "qrcode.react";
import { APIClient } from "../../../helpers/api_helper";
import { toast } from "react-toastify";

const TwoFactorAuth = ({ userId }) => {
  const [loading, setLoading] = useState(true);
  const [has2FA, setHas2FA] = useState(false);
  const [invalidSecret, setInvalidSecret] = useState(false);
  const [qrCodeData, setQRCodeData] = useState(null);
  const [secret, setSecret] = useState("");
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [isVerifying, setIsVerifying] = useState(false);
  const inputRefs = useRef([]);

  const api = new APIClient();

  const check2FAStatus = async () => {
    setLoading(true);
    const response = await api.get("/secrets2fa");
    setLoading(false);

    if (response.sucesso) {
      if (!response.dados?.label) {
        setInvalidSecret(true);
        setHas2FA(false);
      } else {
        setInvalidSecret(false);
        setHas2FA(true);
      }
    } else if (response.responseCode === 404) {
      setInvalidSecret(false);
      setHas2FA(false);
    } else {
      toast.error(response.mensagem || "Error al verificar el estado del 2FA");
    }
  };

  useEffect(() => {
    check2FAStatus();
  }, []);

  const handleCreate2FA = async () => {
    setLoading(true);
    const response = await api.post("/secrets2fa");
    setLoading(false);

    if (response.sucesso && response.dados) {
      setQRCodeData(response.dados.qr_code);
      setSecret(response.dados.secret.secret);
    } else {
      toast.error(response.mensagem || "Error al generar el código 2FA");
    }
  };

  const handleCopySecret = async () => {
    try {
      await navigator.clipboard.writeText(secret);
      toast.success("Código secreto copiado al portapapeles");
    } catch (err) {
      toast.error("Error al copiar el código secreto");
    }
  };

  const handleVerify = async (code) => {
    setIsVerifying(true);
    const response = await api.post("/secrets2fa/verify-first-time", {
      code,
    });
    setIsVerifying(false);

    if (response.sucesso) {
      toast.success("¡2FA activado con éxito!");
      setQRCodeData(null);
      check2FAStatus();
      setVerificationCode(["", "", "", "", "", ""]);
      setInvalidSecret(false);

      // Update authUser in localStorage
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      if (authUser) {
        authUser.needs_2fa = true;
        localStorage.setItem("authUser", JSON.stringify(authUser));
      }
    } else {
      toast.error(response.mensagem || "Error al verificar el código");
      // Reset inputs on error
      setVerificationCode(["", "", "", "", "", ""]);
      inputRefs.current[0]?.focus();
    }
  };

  const handleCodeChange = async (index, value) => {
    if (value.length > 1) value = value[0];
    if (!/^\d*$/.test(value)) return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value !== "") {
      // Move to next input
      if (index < 5) {
        inputRefs.current[index + 1]?.focus();
      } else {
        // If it's the last digit, verify the code
        const fullCode = newCode.join("");
        if (fullCode.length === 6) {
          await handleVerify(fullCode);
        }
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && verificationCode[index] === "" && index > 0) {
      // Move to previous input and clear it
      const newCode = [...verificationCode];
      newCode[index - 1] = "";
      setVerificationCode(newCode);
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleReset2FA = async (userId) => {
    setLoading(true);

    const response = await api.delete("/secrets2fa", {
      user_id: userId,
      password: password,
    });
    setLoading(false);

    if (response.sucesso) {
      toast.success("¡2FA restablecido con éxito!");
      setHas2FA(false);
      setInvalidSecret(false);
      setQRCodeData(null);
      setPassword("");
      setVerificationCode(["", "", "", "", "", ""]);

      // Update authUser in localStorage
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      if (authUser) {
        authUser.needs_2fa = false;
        localStorage.setItem("authUser", JSON.stringify(authUser));
      }
    } else {
      toast.error(response.mensagem || "Error al restablecer el 2FA");
    }
  };

  if (loading) {
    return (
      <Card>
        <CardBody className="text-center">
          <Spinner />
        </CardBody>
      </Card>
    );
  }

  if (invalidSecret) {
    return (
      <Card>
        <CardBody>
          <div className="text-center mb-3">
            <i className="ri-error-warning-line text-warning fs-1"></i>
            <h5 className="mt-2">Configuración 2FA incompleta</h5>
            <p className="text-muted mb-4">
              Es necesario reiniciar la configuración del 2FA
            </p>
          </div>
          <div className="mb-3">
            <Label htmlFor="password">Contraseña</Label>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Ingrese su contraseña"
            />
          </div>
          <div className="text-center">
            <Button color="primary" onClick={() => handleReset2FA(userId)}>
              Reiniciar configuración 2FA
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }

  if (has2FA) {
    return (
      <Card>
        <CardBody>
          <div className="text-center mb-3">
            <i className="ri-shield-check-line text-success fs-1"></i>
            <h5 className="mt-2">2FA está activado</h5>
          </div>
          {/* incluir campo de password para restablecer */}
          <div className="mb-3">
            <Label htmlFor="password">Contraseña</Label>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Ingrese su contraseña"
            />
          </div>
          <div className="text-center">
            <Button color="danger" onClick={() => handleReset2FA(userId)}>
              Restablecer 2FA
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }

  if (qrCodeData) {
    return (
      <Card>
        <CardBody>
          <div className="text-center mb-4">
            <div
              style={{
                background: "white",
                padding: "20px",
                display: "inline-block",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              }}
            >
              <QRCodeSVG value={qrCodeData} size={200} />
            </div>
            <p className="mt-4 mb-2">
              Escanee el código QR con Google Authenticator u otra aplicación
              compatible
            </p>
            <div className="mt-2 mb-4">
              <p className="text-muted mb-2">
                ¿No puede escanear? Copie el código secreto:
              </p>
              <Button color="secondary" size="sm" onClick={handleCopySecret}>
                <i className="ri-file-copy-line me-1"></i>
                Copiar código secreto
              </Button>
            </div>
          </div>
          <div className="mb-4">
            <p className="text-center mb-3">
              Ingrese el código de verificación de 6 dígitos:
            </p>
            <div className="d-flex justify-content-center gap-2">
              {verificationCode.map((digit, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="text"
                  className="form-control text-center fw-bold fs-4"
                  style={{
                    width: "28px",
                    height: "45px",
                    padding: "0.375rem",
                  }}
                  value={digit}
                  onChange={(e) => handleCodeChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  maxLength={1}
                  autoFocus={index === 0}
                />
              ))}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody>
        <div className="text-center">
          <i className="ri-shield-keyhole-line text-primary fs-1"></i>
          <h5 className="mt-2">Active la Autenticación de Dos Factores</h5>
          <p className="text-muted">
            Proteja su cuenta con una capa adicional de seguridad
          </p>
          <Button color="primary" onClick={handleCreate2FA}>
            Activar 2FA
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default TwoFactorAuth;
