import React from "react";
import { Alert } from "reactstrap";

const FormLatam = () => {
  return (
    <Alert color="info" className="text-center">
      ¡Pronto podrás hacer depósitos a través de medios de pago locales de LATAM! Estamos trabajando en ello.
    </Alert>
  );
};

export default FormLatam;
